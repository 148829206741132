<template>
  <div style="width: 100%;height: 100vh">
    <object style="width: 100%;height: 100%" :data="srcRoute" type="application/pdf">
      <embed :src="srcRoute" type="application/pdf">
    </object>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "PdfGeneration",
  data () {
    return {
      srcRoute: axios.api.defaults.baseURL + 'pdf-generator/rh/testing/' + '2c4c3d57-cffd-460f-ae46-0648042a4c40'
    }
  }
}
</script>

<style scoped>
</style>
